<template>
  <section
    v-if="getWebSolutionsSectionData && show"
    ref="web-solutions-section"
    class="section --web-sales-solutions"
    :class="{ '--is-mobile': isMobile }"
  >
    <div>
      <h2 class="title" data-aos="fade-up">{{ getWebSolutionsSectionData.title }}</h2>
      <p class="subtitle --narrow" data-aos="fade-up">
        {{ getWebSolutionsSectionData.subtitle }}
      </p>
    </div>
    <div class="boxes">
      <div data-aos="fade-up">
        <div v-if="jeffBox" class="box --jeff" data-modal="jeff" @click="createModal">
          <div class="content" :class="{ '--hide': modalName === 'jeff' }">
            <div>
              <Box3DIcon />
              <h3 class="name">{{ jeffBox.title }}</h3>

              <p class="desc">
                {{ jeffBox.content }}
              </p>

              <p class="link">{{ getWebSolutionsSectionData.buttonContent }} <ArrowLinearIcon /></p>
            </div>
          </div>
        </div>
      </div>
      <div data-aos="fade-up">
        <div
          v-if="walk3dBox"
          class="box --apartments-360"
          data-modal="apartments"
          @click="createModal"
        >
          <div class="content" :class="{ '--hide': modalName === 'apartments' }">
            <FurnituresIcon />
            <h3 class="name">{{ walk3dBox.title }}</h3>

            <p class="desc">
              {{ walk3dBox.content }}
            </p>
            <p class="link">{{ getWebSolutionsSectionData.buttonContent }} <ArrowLinearIcon /></p>
          </div>
        </div>
      </div>

      <div
        v-show="showModal"
        ref="modal"
        class="modal"
        :style="styles"
        :class="{ '--with-shadow': modalShadow }"
      >
        <CloseIcon class="close" @click.native="close" />
        <iframe
          v-show="showIframe"
          class="iframe"
          :class="{ '--visible': iframeVisibility }"
          v-lazy-load :data-src="iframeSRC"
          allowfullscreen
          scrolling="none"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { isMobile } from '@/utils/mobile-device-detect.js'
import { mapGetters } from 'vuex'

export default {
  name: 'AppWebSolutionsSection',
  components: {
    CloseIcon: () => import('@/components/icons/CloseIcon'),
    ArrowLinearIcon: () => import('@/components/icons/ArrowLinearIcon'),
    Box3DIcon: () => import('@/components/icons/Box3DIcon'),
    FurnituresIcon: () => import('@/components/icons/FurnituresIcon'),
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showModal: false,
      showIframe: false,
      iframeSRC: '',
      iframeVisibility: false,
      modalName: '',
      current: {
        width: 0,
        height: 0,
        top: 0,
        left: 0,
      },
      target: {
        width: 0,
        height: 0,
        top: 0,
        left: 0,
      },
      jeffTarget: {},
      boxBase: {
        width: 0,
        height: 0,
        top: 0,
        left: 0,
      },
      modalShadow: false,
    }
  },
  computed: {
    ...mapGetters(['getWebSolutionsSectionData']),
    jeffBox() {
      return this?.getWebSolutionsSectionData?.webSolutionItem[0]
    },
    walk3dBox() {
      return this?.getWebSolutionsSectionData?.webSolutionItem[1]
    },
    styles() {
      return {
        width: `${this.current.width}px`,
        height: `${this.current.height}px`,
        top: `${this.current.top}px`,
        left: `${this.current.left}px`,
      }
    },
    iframeStyles() {
      return {
        width: `${this.target.width}px`,
        height: `${(this.target.width * 9) / 16}px`,
      }
    },
    isMobile() {
      return isMobile
    },
  },
  beforeDestroy() {
    window.removeEventListener('click', this.clickOutside)
  },
  methods: {
    close() {
      window.removeEventListener('click', this.clickOutside)
      this.showIframe = false
      this.modalShadow = false
      this.current.width = this.boxBase.width
      this.current.height = this.boxBase.height
      this.current.top = this.boxBase.offsetTop
      this.current.left = this.boxBase.left

      window.requestAnimationFrame(() => {
        setTimeout(() => {
          this.modalOpacity = 0.6
          this.iframeVisibility = false
        }, 400)

        setTimeout(() => {
          this.modalName = ''
        }, 500)

        setTimeout(() => {
          this.showModal = false
          this.iframeSRC = ''
        }, 600)
      })
    },
    createModal(e) {
      // const container = this.$refs['web-solutions-section']

      // const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
      const ih = window.innerHeight
      const el = e.target
      const elRect = el.getBoundingClientRect()
      if (!e.target.dataset?.modal) {
        this.iframeSRC = ''
        return
      }

      if (this.isMobile) {
        this.target.width = Math.floor(window.innerWidth)

        this.target.height = ih

        this.target.top = elRect.top + window.scrollY

        this.target.left = Math.ceil((window.innerWidth - this.target.width) / 2)
      }

      if (!this.isMobile) {
        this.target.width = Math.floor(window.innerWidth * 0.8)

        if (this.target.width > 1920) {
          this.target.width = 1920
        }

        this.target.height = Math.floor((this.target.width * 9) / 16)

        this.target.top = elRect.top + window.scrollY

        this.target.left = Math.ceil((window.innerWidth - this.target.width) / 2)
      }

      this.boxBase.width = elRect.width
      this.boxBase.height = elRect.height
      this.boxBase.top = elRect.top + window.scrollY
      this.boxBase.left = elRect.left

      this.current.width = this.boxBase.width
      this.current.height = this.boxBase.height
      this.current.top = this.boxBase.top

      this.current.left = this.boxBase.left

      if (e.target.dataset.modal === 'jeff') {
        this.modalName = 'jeff'
        this.iframeSRC = `${this.jeffBox?.appUrl}?targetWidth=${this.target.width}&targetHeight=${this.target.height}`
      }

      if (e.target.dataset.modal === 'apartments') {
        this.modalName = 'apartments'
        this.iframeSRC = this.walk3dBox?.appUrl
      }
      // this.showIframe = true
      this.iframeSRC = `${this.iframeSRC}`

      window.requestAnimationFrame(() => {
        setTimeout(() => {
          this.showModal = true
        }, 150)

        // setTimeout(() => {
        //   this.showIframe = true
        // }, 200)

        const modal = this.$refs?.modal

        setTimeout(() => {
          this.current.width = this.target.width
          this.current.height = this.target.height
          this.current.top = this.target.top
          this.current.left = this.target.left
          this.showIframe = true
          this.modalShadow = true
          window.addEventListener('click', this.clickOutside)
        }, 250)

        setTimeout(() => {
          const modalYCenterPosition =
            modal.offsetTop - (window.innerHeight - modal.getBoundingClientRect().height) / 2

          window.scrollTo({
            top: modalYCenterPosition,
            behavior: 'smooth',
          })

          this.iframeVisibility = true
        }, 950)
      })
    },
    clickOutside(e) {
      if (this.showModal && !e.target.classList.contains('app-button-box-morph')) this.close()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/components/common/sections/AppWebSolutionsSection';
</style>
