import { UAParser } from 'ua-parser-js'

const UA = new UAParser()

const DEVICE_TYPES = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  SMART_TV: 'smarttv',
  CONSOLE: 'console',
  WEARABLE: 'wearable',
  BROWSER: undefined,
}

const BROWSER_TYPES = {
  CHROME: 'Chrome',
  FIREFOX: 'Firefox',
  OPERA: 'Opera',
  YANDEX: 'Yandex',
  SAFARI: 'Safari',
  INTERNET_EXPLORER: 'Internet Explorer',
  EDGE: 'Edge',
  CHROMIUM: 'Chromium',
  IE: 'IE',
  MOBILE_SAFARI: 'Mobile Safari',
  EDGE_CHROMIUM: 'Edge Chromium',
}
const MOBILE = DEVICE_TYPES.MOBILE
const TABLET = DEVICE_TYPES.TABLET

const browser = UA.getBrowser()
const os = UA.getOS()
const device = UA.getDevice()

const isMobileAndTabletType = () => {
  switch (device.type) {
    case MOBILE:
    case TABLET:
      return true
    default:
      return false
  }
}

const getNavigatorInstance = () => {
  if (typeof window !== 'undefined') {
    if (window.navigator || navigator) {
      return window.navigator || navigator
    }
  }

  return false
}

const isIOS13Check = (type) => {
  var nav = getNavigatorInstance()
  return (
    !!nav &&
    !!nav.platform &&
    // @ts-ignore
    (nav.platform.indexOf(type) !== -1 || (nav.platform === 'MacIntel' && nav.maxTouchPoints > 1 && !window.MSStream))
  )
}

const getIPad13 = () => {
  return isIOS13Check('iPad')
}

export const isFirefox = browser.name === BROWSER_TYPES.FIREFOX
export const isSafari = browser.name === BROWSER_TYPES.SAFARI || browser.name === BROWSER_TYPES.MOBILE_SAFARI
export const isMobile = isMobileAndTabletType() || getIPad13()
export const browserName = browser.name || 'none'
export const browserVersion = browser.version || 'none'
export const osName =  os.name || 'none'
